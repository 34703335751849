.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: url('/public/REMEMBERED-TABLE-33333.png') center center no-repeat;
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

button {
  cursor: "pointer"; 
  background:'transparent';
  box-shadow: 'none';
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.home {
  position: absolute;

  top: 17%;
  left: 22%;
}

.overlay-content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100vh;
}

.transmission {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}

.bg-image { 
  padding: 100px 220px;
}

.bg-content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
